import React, { useEffect } from 'react'
import { HcpPage, SEO } from "components";
import { scrollToElement } from 'libs/util.helper';
import { useWindowSize } from 'hooks';
import './sitemap.scss';

const pageDescription = "Read AVEED® (testosterone undecanoate) injection CIII safety information. Learn about an alternative treatment option. See PI including Boxed Warning.";

const AveedSafetyInfoPage = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768;
  useEffect(() => {
    //Fix for showing double ISI in small pages
    setTimeout(() => {
      scrollToElement('hcp-aveed-safety-info-page-top', isMobile ? 75 : 120)
    }, 100)
  }, [])
  return (
    <HcpPage pageClassName="aveed-safety-info">
      <SEO pageTitle="Site Map" pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <h1 id="hcp-aveed-safety-info-page-top"></h1>
      </div>
    </HcpPage>
  )
}

export default AveedSafetyInfoPage
